//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import SectionTemplate from './SectionTemplate.vue';
export default {
  extends: SectionTemplate,
  data(){
    return {
      svgSpanner: {},
      playerKey: 0,
      spanned: false
    }
  },
  mounted(){
    if(process.browser){
      if(this.data.use_svg) window.addEventListener('DOMContentLoaded', this.prepareSVG());
    }
  },
  beforeDestroy(){
    if(this.data.use_svg) window.removeEventListener('DOMContentLoaded', this.prepareSVG());
  },
  computed: {
    _imagesCertBadges(){
      if(this.data.theme === 'light') return [{
        alt: 'burst sms iso 2007 certified',
        url: '/uploads/iso-2700.svg'
      }];

      return [{
        alt: 'burst sms iso 2007 certified',
        url: '/uploads/iso-2007.svg'
      }]
    },
    _styleSection(){
      return {
        'background-image': 'url('+this.data.background+')'
      }
    }
  },
  methods: {
    prepareSVG(){

      const self = this;
      const svg = self.$refs.svghero;

      svg.addEventListener('ready', e => {
        self.$nextTick(function(){
          const svgData = svg.getLottie();
          if(svgData){
            const pt = (svgData.animationData.h / svgData.animationData.w) * 100;
            self.svgSpanner = { 'padding-top': pt + '%' };
            self.spanned = true
          }
        });
      });

      if(this.data.use_svg){
        let svg = this.$refs.svghero;
        if(svg && svg.length > 0){
          svg = svg[0];
          if(typeof svg !== 'undefined'){
            svg.seek(0);
            svg.play()
          }
        }
      }
    }
  }
}
