
import Vue from 'vue'
import inViewportDirective from 'vue-in-viewport-directive'
import * as snippets from '../snippets';
inViewportDirective.defaults.margin = '-20% 0%'
Vue.directive('viewport', inViewportDirective)
export default {
  data(){
    return {
      sectionID: ''
    }
  },
  components: {
    ...snippets
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  created(){
    this.sectionID = this.$vnode.tag
  },
  mounted(){
    this.sectionID = this.$vnode.tag
  }
}
