//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import { mapState } from 'vuex';
import FooterData from "~/content/data/footer.json";

export default {
  name: 'PageFooter',
  data() {
    return {
      sticky: false,
      attached: false,
      footerAnchorAt: 50,
      footerAnchorOffAt: 0
    }
  },
  computed: {
    ...mapState({
      page: state => state.pages.page,
    }),
    footer() {
      return FooterData;
    },
    yearToday() {
      return (new Date()).getYear() + 1900;
    },
    addGradient() {
      return (['index']).includes(this.page.slug);
    },
    footerShowAt() {
      // const isHomePage = (['index']).includes(this.page.slug);
      //
      // if(isHomePage){
      //   if(window.innerHeight < 900) return document.querySelector('[data-section="stat-capsule"]').nextSibling.offsetTop - window.innerHeight + 100;
      //
      //  return document.querySelector('[data-section="stat-capsule"]').nextSibling.offsetTop - window.innerHeight + 100;
      // }

      return 127;
    }
  },
  beforeMount() {
    // window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    $('.row-menu .col-menu').on('click', function () {
      $(this).toggleClass('open');
      $(this).siblings().removeClass('open');
    });
  },
  methods: {
    isRelative(url) {
      const r = new RegExp('^(?:[a-z]+:)?//', 'i');
      return !r.test(url);
    },
    handleScroll() {
      const scrollY = $(window).scrollTop();
      const footerAnchorOffAt = document.querySelector('footer').offsetTop - window.innerHeight + 130 + 75

      this.sticky = scrollY >= this.footerShowAt;
      this.attached = scrollY >= footerAnchorOffAt;
    }
  }
}
