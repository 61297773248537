//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import SectionTemplate from './SectionTemplate.vue';
export default {
  extends: SectionTemplate,
  components: {
    VueSlickCarousel
  },
  data(){
    return {
      active: 0,
      slickOptions: {
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        centerMode: false,
        infinite: false,
        arrows: false,
        variableWidth: true,
        centerPadding: '0'
      }
    }
  },
  methods: {
    getContextClass(color) {
      if (color === 'light blue') return 'card--light-blue';
      else if (color === 'yellow') return 'card--yellow';
      else if (color === 'orange') return 'card--orange';
      else return '';
    },
    afterChange(slide){
      this.active = slide;
      $('[data-section="feature-cards"] .slick-slide').addClass('faded');
      $('[data-section="feature-cards"] .slick-slide[data-index="'+slide+'"]').removeClass('faded');
      $('[data-section="feature-cards"] .slick-slide[data-index="'+(slide-1)+'"]').removeClass('faded');
    }
  }
}
