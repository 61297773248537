import { render, staticRenderFns } from "./SliderCards.vue?vue&type=template&id=5ab759ae&"
import script from "./SliderCards.vue?vue&type=script&lang=js&"
export * from "./SliderCards.vue?vue&type=script&lang=js&"
import style0 from "~/assets/styles/sections/slider-cards.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports