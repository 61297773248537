//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data(){
    return {
      portalSearchBase: 'https://portal.burstsms.com/s/search/',
      keywords: [
        'Global SMS Delivery List',
        'SMS SPAM Compliance Masterclass',
        'How to track a link within your message',
        'What does a message with a pending status mean?',
      ]
    }
  },
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  methods: {
    encodeString(search){
      return encodeURIComponent(search).replace(/%20/g, "+");
    }
  }
}
