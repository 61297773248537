//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import { mapState } from 'vuex';
import * as MenuLayout from '~/components/menu'

import Header from "~/content/data/header.json"
export default {
    name: 'PageHeaderPlain',
    components: {
        ...MenuLayout
    },
    data() {
        return {
            active: null,
            sticky: false,
            lastScrollTop: 0,
            lastScrollDelta: 0,
            scrolled: false,
            scrolling: false,
            scrollingTimeout: null,
            navDesktopOpen: false,
            mobileOpen: false,
            open: false,
            megaMenuStyle: {
                'height': '500px'
            },
            allowNavOpen: { "top": 0, "left": 0, "bottom": 0, "right": 0 }
        }
    },
    computed: {
        ...mapState({
            page: state => state.pages.page,
        }),
        header: () => Header,
        theme() {
            if (this.page && this.page.header) return 'navbar-' + (this.page.header.theme || 'dark');
            return 'navbar-dark';
        },
        _headerLogo() {
            if(this.mobileOpen) return require('~/assets/images/conversr-logo-kudo.svg');
            if(this.open) return require('~/assets/images/conversr-logo-kudo.svg');
            if (this.theme === 'navbar-dark') return require('~/static/uploads/conversr-logo-kudo-white.svg');
            return require('~/assets/images/conversr-logo-kudo.svg');
        }
    },
    methods: {
        _navClose(event) {
            if (event) {
                if (this._checkIfBounds({ x: event.clientX, y: event.clientY }, this.allowNavOpen)) return;
            }
            this.open = false;
            this.mobileOpen = false;
            this._setActiveHover(null);
            $('body').removeClass('nav--open');
            $('body').removeClass('nav-mobile--open');
        },
        _toggleNavMobile() {
            this.mobileOpen = ! this.mobileOpen;
            $('body').toggleClass('nav-mobile--open');
        },
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            if (to.name !== "slug") {
                window.location.assign(to.path.replace('/http', 'http'));
                next(false);
            } else {
                this._navClose();
                next();
            }

        })
    }
}
