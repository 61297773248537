//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import Locations from "~/content/data/locations.json";
export default {
  props: {
    mobile: {
      type: Array,
      required: true
    },
    country: {
      type: String,
      default: 'Australia'
    }
  },
  data(){
    return {
      active: null,
      activeLabel: null,
      level: 0,
      location: null,
      locations: null
    }
  },
  created(){
    this.location = {...Locations.find(c => c.name.toLowerCase() === this.country.toLowerCase())};
    // remove current location
    this.locations = Locations.filter(c => c.name.toLowerCase() !== this.country.toLowerCase());
  },
  methods: {
    _setActiveMenu(index, label, level){
      this.active = index;
      this.activeLabel = label;
      this.level = level;

      if(index !== null){
        $('body').addClass('nav-mobile-submenu--open');
      }else{
        $('body').removeClass('nav-mobile-submenu--open');
      }
    }
  }
}
