import jQuery from 'jquery';

window.jQuery = jQuery;

require('bootstrap');

(function($) {
  $('[data-toggle="dropdown"]').dropdown();
  $('[data-toggle="tab"]').on('click', function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  $('body').on('click', '.clickable', function(e) {
    const link = $(this)
      .find('a:not([href=""])')
      .first();

    if (link) {
      e.preventDefault();
      if (link.attr('target') === '_blank') {
        window.open(link.attr('href'), '_blank');
        return false;
      }
      window.location.assign(link.attr('href'));
      return false;
    }

    return true;
  });

  $('body').on('click', '.btn[href="#contact-us"]', function(event) {
    event.preventDefault();
    $('#form-request')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  });

  if (window.location.hash.includes('#contact-us')) {
    $('#form-request')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  }

  $('body').on('click', '.btn[href^="#"]', function(event) {
    const hashBtn = $($(this).attr('href'));
    const offset = parseInt($(this).data('offset')) || -50;

    if (hashBtn.length > 0) {
      event.preventDefault();
      $('html, body').animate(
        {
          scrollTop: hashBtn.offset().top + offset
        },
        500
      );

      $('body').removeClass('nav-mobile-submenu--open nav-mobile--open');
    }
  });
})(jQuery);
