import GlobalConfig from "~/content/data/config.json"
export default function (page,app) {

  const head = {
    title: page.title,
    meta: [],
    script: [
      {
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "image": [
            "https://conversr.com/uploads/conversr-logo.png"
          ],
          "@id": "https://conversr.com",
          "url": "https://conversr.com",
          "name": "Conversr",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Suite 2, Level 10 60 Carrington Street",
            "addressLocality": "Sydney",
            "addressRegion": "NSW",
            "postalCode": "2000",
            "addressCountry": "Australia"
          },
          "description": "Provide the best customer experience with Conversr, our award winning AI-powered conversational platform.",
          "priceRange": "",
          "telephone": "1300012014"
        }
      }
    ]
  };

  const options = {
    url: "https://conversr.com/",
    title: page.title || GlobalConfig.title,
    site_name: "Conversr",
    description: page.description || GlobalConfig.description,
    img: page.image ? `https://conversr.com${page.image}` : "https://conversr.com/uploads/conversr-logo.png",
    locale: "en_AU",
    twitter: "@conversr",
    twitter_card: "summary_large_image",
    themeColor: "#62dbd9",
  };
  let keywords = GlobalConfig.keywords;

  if(page.keywords && page.keywords.length > 0)
    keywords = page.keywords

  const metaTags = [

    { name: 'description', content: options.description },
    { name: 'keywords', content: keywords.join(', ') },
    // Global
    { name: "author", content: options.url },
    { name: "publisher", content: options.url },
    { name: "apple-mobile-web-app-title", content: options.title },
    { name: "theme-color", content: options.themeColor },

    // Facebook & LinkedIn
    { property: "og:title", content: options.title },
    { property: "og:description", content: options.description },
    { property: "og:type", content: "website" },
    { property: "og:url", content: options.url },
    { property: "og:image", content: options.img },
    { property: "og:locale", content: options.locale },
    { property: "og:site_name", content: options.site_name },

    // Twitter
    { name: "twitter:card", content: options.twitter_card },
    { name: "twitter:site", content: options.twitter },
    { name: "twitter:creator", content: options.twitter },
    { name: "twitter:title", content: options.title },
    { name: "twitter:description", content: options.description },
    { name: "twitter:image", content: options.img },
  ];

  metaTags.forEach((tag) => {
    if (tag.content !== undefined && tag.content !== null) {
      if (tag.hasOwnProperty("name")) {
        head.meta.push({
          hid: tag.name,
          name: tag.name,
          content: tag.content,
        });
      }else if(tag.hasOwnProperty("property")){
        head.meta.push({
          hid: tag.property,
          property: tag.property,
          content: tag.content,
        });
      }
    }
  });

  return head;
}
