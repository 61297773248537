//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {

    }
  },
  props: {
    nav: {
      type: Object,
      required: true
    }
  }
}
