//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    country: {
      type: String,
      default: 'Australia'
    }
  },
  data() {
    return {
      selectedCountry: null,
      countries: [
        {
          name: "Australia",
          url: "https://burstsms.com.au",
          image: "/uploads/flag-australia-desktop.png"
        },
        {
          name: "Canada",
          url: "https://www.burstsms.ca",
          image: "/uploads/flag-canada-desktop.png"
        },
        {
          name: "Germany",
          url: "http://go.burstsms.com/deutschland",
          image: "/uploads/flag-germany-desktop.png"
        },
        {
          name: "Hong Kong",
          url: "https://www.burstsms.com.hk",
          image: "/uploads/flag-hong-kong-sar-china-desktop.png"
        },
        {
          name: "New Zealand",
          url: "https://www.burstsms.co.nz",
          image: "/uploads/flag-new-zealand-desktop.png"
        },
        {
          name: "Philippines",
          url: "https://burstsms.com.ph",
          image: "/uploads/flag-philippines-desktop.png"
        },
        {
          name: "Singapore",
          url: "https://www.burstsms.com.sg",
          image: "/uploads/flag-singapore-desktop.png"
        },
        {
          name: "United Kingdom",
          url: "https://www.burstsms.co.uk",
          image: "/uploads/flag-united-kingdom-desktop.png"
        },
        {
          name: "United States",
          url: "https://www.burstsms.com",
          image: "/uploads/flag-united-states-desktop.png"
        }
      ]
    };
  },
  created() {
    this.selectedCountry = {...this.countries.find(c => c.name.toLowerCase() === this.country.toLowerCase())};
    // remove current location
    this.countries = this.countries.filter(c => c.name.toLowerCase() !== this.country.toLowerCase());
  }
}
