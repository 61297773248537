//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";

export default {
  data() {
    return {
      elapsed: 0,
      targetDate: new Date("Nov 16 2023 00:00:00 GMT+1100"),
      modalDisplayed: false,
      intervalFuncId: null,
      scrollMarker: null
    };
  },
  computed: {
      days() {
      const d = Math.floor(this.elapsed / 86400000);

      if (d > 0) {
          return d // return String(d).padStart(2, '0');
      } else {
          return 0 // return String(0).padStart(2, '0');
      }
      },
      hours() {
      const h = Math.floor((this.elapsed % 86400000) / 3600000);

      if (h > 0) {
          return h // return String(h).padStart(2, '0');
      } else {
          return 0 // return String(0).padStart(2, '0');
      }
      },
      minutes() {
      const m = Math.floor(((this.elapsed % 86400000) % 3600000) / 60000);

      if (m > 0) {
          return m // return String(m).padStart(2, '0');
      } else {
          return 0 // return String(0).padStart(2, '0');
      }
      },
      seconds() {
      const s = Math.floor((((this.elapsed % 86400000) % 3600000) % 60000) / 1000);

      if (s > 0) {
          return s // return String(s).padStart(2, '0');
      } else {
          return 0 // return String(0).padStart(2, '0');
      }
      },
  },
  methods: {
      clearCountdown() {
          if (this.intervalFuncId) {
              clearInterval(this.intervalFuncId)
              // console.log('done')
          }

          window.removeEventListener('scroll', this.handleScroll);
      },
      showModal() {
          $("#countdown-modal").modal("show");
      },
      startCountdown() {
          if (this.modalDisplayed) {

          } else {
              this.modalDisplayed = true
              this.showModal()

              this.intervalFuncId = setInterval(() => {
                  this.elapsed = (this.targetDate - new Date());
              }, 500);
          }
      },
      handleScroll() {
          // show popup after slider
          if (this.scrollMarker == null) {
              this.scrollMarker = document.querySelector('[data-section="slider-solutions"]')
          }

          if (this.scrollMarker) {
              const elBottom = this.scrollMarker.getBoundingClientRect().bottom
              // console.log(elBottom)

              if (elBottom <= 100) {
                  this.startCountdown()
                  window.removeEventListener('scroll', this.handleScroll);
              }
          }
      }
  },
  mounted() {
      $('#countdown-modal').on('hidden.bs.modal', () => {
          this.clearCountdown()
      })

      $('#countdown-modal').on('show.bs.modal', () => {
          document.querySelector('body').classList.add('countdown-modal-shown')
      })

      this.elapsed = (this.targetDate - new Date());

      setTimeout(() => {
          this.startCountdown()
      }, 30000);

      window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
      this.clearCountdown()
      window.removeEventListener('scroll', this.handleScroll);
  }
};
