//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    options: {
      type: Object,
      default(){
        return {
          bg_color: 'Primary',
          text_color: 'Light',
          rounded: true,
          icon: 'arrow'
        }
      },
      required: true,
    },
  },
  computed: {
    isExternal(){
      return !(this.options.url.startsWith('/') || this.options.url.includes(process.env.SITE_PATH || "https://conversr.com"));
    },
    target(){
      if(this.options.new_tab) return "_blank";
      return "_self";
    },
    btnClass(){
      const classes = [
        'btn section-button'
      ];

      if(this.options.bg_color === 'Default') classes.push('btn-default');
      if(this.options.bg_color === 'Primary') classes.push('btn-primary');
      if(this.options.bg_color === 'Secondary') classes.push('btn-secondary');
      if(this.options.bg_color === 'White') classes.push('btn-white');

      if(this.options.rounded) classes.push("btn--rounded");
      if(this.options.shadow) classes.push("btn--shadow");

      return classes.join(' ');
    }
  }
}
