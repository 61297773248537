//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      selectedSnippet: this.snippets[0],
      copyButtonLabel: 'Copy Snippet',
      hovered: false,
      interacted: false
    }
  },
  props: {
    snippets: {
      type: Array,
      required: true
    }
  },
  methods: {
    copyToClipboard() {
      // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
      const text = this.$el.querySelector('.code-tab__snippet').textContent;

      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

      } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        const textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();

        try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
          window.prompt("Copy to Clipboard",text);
          console.warn("Copy to clipboard failed.", ex);
          return false;
        }
        finally {
          document.body.removeChild(textarea);
          this.copied();
        }
      }
    },
    selectSnippet(snippet) {
      this.selectedSnippet = snippet;
      this.interacted = true;

      if (this.interactedDecayTimeout) clearTimeout(this.interactedDecayTimeout)
        this.interactedDecayTimeout = setTimeout(() => {
        this.interacted = false;
      }, 7000);
    }
  },
  computed: {
    content() {
      const filtered = this.snippets.filter((snippet) => snippet.title === this.selectedSnippet.title);
      if (filtered.length) {
        return filtered[0].content;
      } else {
        return null;
      }

    }
  },
  created() {
    this.copiedTimeout = null;

    this.copied = () => {
      this.copyButtonLabel = 'Copied!';

      if (this.copiedTimeout) {
        clearTimeout(this.copiedTimeout);
      }

      this.copiedTimeout = setTimeout(() => {
        this.copyButtonLabel = 'Copy Snippet'
      }, 1700);
    }

    this.sleep = (m) => new Promise(resolve => setTimeout(resolve, m));

    this.nextSnippet = function() {

      if (this.hovered) {
        return Promise.resolve('hovered');
      }

      if (this.interacted) {
        return Promise.resolve('interacted');
      }

      let current = this.snippets.indexOf(this.selectedSnippet);
      current = (current + 1) % this.snippets.length;
      this.selectedSnippet = this.snippets[current]

      return Promise.resolve();
    }

    this.loop = async function() {
      await this.sleep(3600);
      await this.nextSnippet();

      // https://stackoverflow.com/questions/38399050/vue-equivalent-of-settimeout
      setTimeout(() => this.loop().then(() => { }, (e) => { console.log('rejected ', e) }));
      return Promise.resolve();
    };
  },
  mounted() {
    this.loop().then(() => { }, (e) => { console.log('rejected ', e) });
  }
}
