//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTemplate from './SectionTemplate.vue';
export default {
  extends: SectionTemplate,
  methods: {
    getContextClass(color) {
      if (color === 'light blue') return 'card-title--light-blue';
      else return '';
    }
  }
}
