//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import SectionTemplate from './SectionTemplate.vue';
export default {
  extends: SectionTemplate,
  components: {
    VueSlickCarousel
  },
  data(){
    return {
      highlight: {
        left: '0px',
        width: '0px'
      },
      active: 0,
      slickOptions: {
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        centerMode: false,
        infinite: false,
        arrows: false,
        variableWidth: false,
        focusOnSelect: true,
        responsive: [{
          breakpoint: 991,
          settings: {
            centerPadding: '0',
            centerMode: false,
            infinite: false,
            slidesToShow: 1,
            variableWidth: false
          }
        }]
      }
    }
  },
  mounted(){
    document.addEventListener('DOMContentLoaded', this.moveHighlight());
  },
  created(){
    if(process.browser){
      $(window).resize(this.moveHighlight);
    }
  },
  methods: {
    beforeChange(currentSlide, nextSlide){
      this.active = nextSlide;
      this.moveHighlight();
    },
    afterChange(slide){
      // this.active = slide;
      $('[data-section="slider-cards"] .slick-slide').addClass('faded');
      $('[data-section="slider-cards"] .slick-slide[data-index="'+slide+'"]').removeClass('faded');
      $('[data-section="slider-cards"] .slick-slide[data-index="'+(slide-1)+'"]').removeClass('faded');
    },
    goTo(slide) {
      this.$refs.carousel.goTo(slide);
    },
    moveHighlight() {
      let pos = 0;
      const width = this.$refs.tabs[this.active].getBoundingClientRect().width;
      pos = width * this.active;
      
      this.highlight.left = pos + 'px';
      this.highlight.width = width + 'px';

      this.$forceUpdate();
    }
  }
}
