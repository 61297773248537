import axios from 'axios'

export default async function ({ route, store, redirect }) {

  const paths = ['/free-trial','/free-trial/'];

  // console.log(route.path,blockListCountry,blockListIP);
  if(paths.includes(route.path)){

    const blockListCountry = (process.env.FILTER_COUNTRY || '').split(',');
    const blockListIP = (process.env.FILTER_IP || '').split(',');
    const whiteListIP = (process.env.FILTER_IP_WHITE || '').split(',');

    const blackListed = await axios.get("https://freegeoip.app/json/").then(function(response) {
      if(response && response.data){
        sessionStorage.setItem('burst-geo', JSON.stringify(response.data));

        if(whiteListIP.includes(response.data.ip)){
          return false;
        }

        return (blockListCountry.includes(response.data.country_code) || blockListIP.includes(response.data.ip));
      }
      // console.log(response.data);
      return false;
    });

    if(blackListed){
      return redirect('/unavailable/');
    }

    return true;
  }

}
