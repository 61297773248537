export const state = () => ({
  page: {},
  layout: 'default'
})

export const actions = {
  scroll({ commit, state }, scroll){
    commit('scroll',scroll);
  }
}

export const mutations = {
  set(state, page) {

    page.sticky =  false;
    page.scrolled =  false;
    page.scrolling =  false;

    state.page = page
    if(page.layout){
      state.layout = page.layout
    }
  },
  scroll(state,scroll){
    state.page.sticky =  scroll.sticky;
    state.page.scrolled =  scroll.scrolled;
    state.page.scrolling =  scroll.scrolling;
  }
}
