//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as snippets from '~/components/snippets';
export default {
  components: {
    ...snippets
  },
  data(){
    return {
      hoverItemStyle: {
        width: '282px',
        height: '80px',
        left: 0,
        top: 0
      }
    }
  },
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  methods: {
    _hoverTabItem($event){
      const self = this;
      const $elem = $event.target;

      const padding = { x: 30 , y : 20 };
      self.hoverItemStyle.width = 200 + (padding.x) + 'px';
      self.hoverItemStyle.height = $elem.clientHeight + 'px';
      self.hoverItemStyle.left =   $elem.offsetLeft + 'px';
      self.hoverItemStyle.top =   $elem.offsetTop - (padding.y / 2) + 'px';
    }
  }
}
