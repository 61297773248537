//
//
//
//
//
//
//
//

// import * as sections from '~/components/sections';
import "~/components/filters.js";
import socialize from "~/components/social";
// import CountdownModal from "~/layouts/partials/CountdownModal.vue";
// import SideModal from "~/layouts/partials/SideModal.vue";
const slug = "index"

export default {
  head() {
    return socialize(this.page)
  },
  components: {
    // CountdownModal,
    // SideModal
  },
  async asyncData({ app, store, $content, params }) {
    const page = await $content('pages',slug).fetch()
    store.commit('pages/set',page);
    return {
      page
    }
  }
}
