//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import "~/components/filters.js";
import * as TabLayout from './tab';

import MenuFooter from './MenuFooter.vue';
export default {
  components: {
    MenuFooter,
    ...TabLayout
  },
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      active: null
    }
  },
  mounted(){
    if(process.browser){
      $(function(){
        $('[data-menu-template="tabs"]').each(function(){
          $(this).find('.nav-tabs .nav-item').first().find('[data-toggle="tab"]').first().tab('show');
        });
      });
    }
  },
  methods: {
    _toggleTab($event,index){
      $($event.target).find('[data-toggle="tab"]').first().tab('show').on('shown.bs.tab', function (e) {
        $($(e.relatedTarget).attr('href')).removeClass('active show');
        $(e.relatedTarget).closest('.megamenu').css({
          'height': $(this).closest('.menu-content').height()
        });
      });
    },
    _getTemplate(tab){
      const _slug = this.$options.filters.slugify(tab.label);
      let _template = false;
      switch (_slug) {
        case "api-documentation":
        _template = 'card-column';
        break;
        case "strategic-resources":
        _template = 'box-column';
        break;
        case "support-portal":
        _template = 'portal';
        break;
        case "contact-us":
        default:
        _template = 'card-column';
        break;
      }

      return _template;
    }
  }
}
