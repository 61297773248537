//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import "~/components/filters.js";

export default {
  data(){
    return {
      hoverItemStyle: {
        width: '282px',
        height: '80px',
        left: 0,
        top: 0
      }
    }
  },
  components: {
  },
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  computed: {
    tab(){
      if(this.menu && this.menu.tabs.length > 0) return this.menu.tabs[0];
      return {};
    }
  },
  methods: {
    _hoverTabItem($event){
      const self = this;
      const $elem = $event.target;

      const padding = { x: 30 , y : 20 };
      self.hoverItemStyle.width = $elem.clientWidth + (padding.x * 2) + 'px';
      self.hoverItemStyle.height = $elem.clientHeight + (padding.y * 2) + 'px';
      self.hoverItemStyle.left =   $elem.offsetLeft + (padding.x / 2) + 'px';
      self.hoverItemStyle.top =   $elem.offsetTop + (padding.y) + 'px';
    }
  }
}
