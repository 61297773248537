//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { iframeResizer } from "iframe-resizer";
import SectionTemplate from "./SectionTemplate.vue";
export default {
    extends: SectionTemplate,
    data() {
        return {
            maintenance: false
        }
    },
    created() {
        if (process.browser) {
            if (process.env) {
                this.maintenance = !!process.env.MAINTENANCE
            }

            this.initIframeResizer();
        }
    },
    mounted() {
        this.initIframeResizer();
    },
    computed: {
        iframeURL() {
            if (typeof window === "undefined") return this.data.link;

            const search = new URLSearchParams(window ? window?.location?.search : "");

            search.append("Referrer", document?.referrer || (window ? window?.location.href : ""));
            search.append("Website_URL", window ? window?.location.href : "https://burstsms.com");
            search.append("Website_Source", "Burst US");

            return this.data.link + "?" + search.toString();
        },
        currentUrl() {
            if (window) return window.location.href;
            return "";
        }
    },
    methods: {
        initIframeResizer() {
            iframeResizer(
                {
                    log: false,
                    checkOrigin: false,
                    heightCalculationMethod: "max",
                    onResized(data) {
                        console.log("The iframe size has been changed:", data);
                        data.fontSize = parseFloat(window ? window.getComputedStyle(document.body).getPropertyValue("font-size") : 16);
                        data.iframe.iFrameResizer.sendMessage(data);
                    },
                },
                this.$refs.myIframe
            );
        },
    },
};
