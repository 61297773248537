//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel';
// import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    partners: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      slickOptions: {
        "slidesToShow": this.partners.length,
        "slidesToScroll": 0,
        "autoplay": false,
        "swipe": false,
        "dots": false,
        "arrows": false,
        "speed": 300,
        "focusOnSelect": false,
        "touchThreshold": 5,
        "variableWidth": true,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
              "autoplay": true,
              "swipe": true,
              "variableWidth": false,
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 1,
              "autoplay": true,
              "swipe": true,
              "variableWidth": false,
            }
          },
          {
            "breakpoint": 540,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
              "autoplay": true,
              "swipe": true,
              "variableWidth": false,
            }
          },
          {
            "breakpoint": 350,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "autoplay": true,
              "swipe": true,
              "variableWidth": false,
            }
          }
        ]
      }
    }
  }
}
