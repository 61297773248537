import Vue from 'vue';

const slug = function(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  const to   = "aaaaaeeeeeiiiiooooouuuunc------";
  for (let i=0, l=from.length ; i<l ; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
  .replace(/\s+/g, '-') // collapse whitespace and replace by -
  .replace(/-+/g, '-'); // collapse dashes

  return str;
};

Vue.filter('idSlug', function(value) {
  return '#' + slug(value);
});
Vue.filter('slugify', function(value) {

  return slug(value);
});
Vue.filter('append', function(value,...string) {
  return value + string.join('');
});

Vue.mixin({
  methods: {
    placeholder(w,h) {
      return "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='"+ w +"' height='"+ h +"' viewBox='0 0 "+ w +" "+ h +"'><path d='M0,0H"+ w +"V"+ h +"H0Z' fill='none'/></svg>";
    },
  },
})
