//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import { mapState, mapActions } from 'vuex';
import PageHeader from './partials/PageHeader.vue';
import PageHeaderPlain from './partials/PageHeaderPlain.vue';
import PageFooter from './partials/PageFooter.vue';

export default {
  scrollToTop: true,
  head() {
    const meta = [
      {
        rel: 'alternate',
        hreflang: 'en-au',
        href:
          'https://conversr.com' +
          this.$nuxt.$route.path.toString().replace(/^(.+?)\/*?$/, '$1') +
          '/'
      }
    ];

    if (this.page.slug === 'index') {
      meta.push({
        rel: 'prefetch',
        href: '/uploads/background-home.svg'
      });
      meta.push({
        rel: 'prefetch',
        href: '/uploads/home-hero-image.svg'
      });
    }

    return {
      meta
    };
  },
  data() {
    return {
      scrolled: false,
      lastScrollTop: 0,
      lastScrollDelta: 0,
      scrollingTimeout: null
    };
  },
  components: {
    PageHeader,
    PageHeaderPlain,
    PageFooter
  },
  computed: {
    ...mapState({
      page: (state) => state.pages.page
    }),
    layout() {
      return this.page.layout || 'default';
    },
    otherClasses() {
      const classes = [];
      const refreshedPages = [
        'index',
        'two-factor-authentication',
        'customer-service',
        'sms-alerts-and-notifications',
        'sms-reminders',
        'not-for-profit',
        'sms-for-healthcare',
        'operations',
        'sms-marketing',
        'quick-sms-launcher'
      ];

      if (this.page.scrolled) classes.push('layout--scrolled');
      if (this.page.scrolled && this.page.scrolling)
        classes.push('scrolling--' + this.page.scrolling);

      if (refreshedPages.includes(this.page.slug))
        classes.push('page--refresh');

      classes.push('sticky-' + (this.page.sticky ? 'on' : 'off'));

      return classes.join(' ');
    }
  },
  methods: {
    ...mapActions({
      scroll: 'pages/scroll'
    })
  },
  created() {
    if (process.browser) {
      // history.scrollRestoration = "manual";
      // window.onbeforeunload = function () {
      //   window.scrollTo(0, 0);
      // }

      // const self = this;

      $(function() {
        // $(window).scroll(function(event){
        //   console.log(event);
        // });
        // self.scrolled = $(window).scrollTop() > 127;
        // let lastDownDelta = 0;
        // let lastUpDelta = 0;
        //
        // $(window).scroll(function(){
        //
        //   self.scrolled = $(this).scrollTop() > 127;
        //
        //   if(self.scrollingTimeout) clearTimeout(self.scrollingTimeout);
        //   const st = $(this).scrollTop();
        //
        //   let scrolled = false;
        //   let scrolling = false;
        //   let sticky = false;
        //   const scrollDelta = st - self.lastScrollTop
        //
        //   if( st > 127){
        //
        //
        //     scrolled = true;
        //     if (scrollDelta > 0){
        //       scrolling = 'down';
        //       sticky = false;
        //       lastDownDelta = st;
        //     }else{
        //
        //       lastUpDelta = st;
        //       if(lastDownDelta - lastUpDelta > 300){
        //         scrolling = 'up';
        //         sticky = true;
        //       }
        //
        //     }
        //
        //   }else{
        //     scrolled = false;
        //   }
        //
        //   self.lastScrollTop = st;
        //
        //   self.scroll({
        //     sticky,
        //     scrolled,
        //     scrolling
        //   });
        //
        // });
      });
    }
  }
};
