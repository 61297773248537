//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";

export default {
  data() {
    return {
      modalDisplayed: false,
      scrollMarker: null
    };
  },
  computed: {
  
  },
  methods: {
      clearModal() {
          window.removeEventListener('scroll', this.handleScroll);
      },
      showModal() {
          $("#side-modal").modal("show");
      },
      startModal() {
          if (this.modalDisplayed) {

          } else {
              this.modalDisplayed = true
              this.showModal()
          }
      },
      handleScroll() {
          // show popup after webinar banner
          if (this.scrollMarker == null) {
              this.scrollMarker = document.querySelector('[data-section="slider-solutions"]')
          }

          if (this.scrollMarker) {
              const elBottom = this.scrollMarker.getBoundingClientRect().bottom
              // console.log(elBottom)

              if (elBottom <= 100) {
                  this.startModal()
                  window.removeEventListener('scroll', this.handleScroll);
              }
          }
      }
  },
  mounted() {
      $('#side-modal').on('hidden.bs.modal', () => {
          this.clearModal()
      })


      setTimeout(() => {
          this.startModal()
      }, 3000);

      window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
      this.clearModal()
      window.removeEventListener('scroll', this.handleScroll);
  }
};
